import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon, Stepper, Loader, Button, Select, Input, DatePicker } from '@scuf/common'
import {
  DrilldownContainer,
  Title,
  GeneralContainer,
  BreadcrumbContainer,
  BreadcrumbTitle,
  ButtonAsAlerts,
  ModalBody,
  ScheduleForm,
  Column,
  ButtonContainer,
  StepOne,
  Paragraph,
  TimeWrapper,
  IndividualTime,
  SelectorContainer,
  SiteSelector,
  IconContainer,
  StyledCheckbox
} from './NewReportForm.styles'
import { isSelectRequired, isRequired } from 'Components/../Forms/Validators'
import { FormRow } from 'Themes/ScufStyledComponents'
import { FREQUENCIES, DAYS } from './NewReportForm.helpers'
import WeekDaySelector from './components/weekday-selector'
import { useAutomatedReport } from './NewReportForm.hooks'
import Collapse from '../../../collapse'
import AlertSubcategory from '../../../alerts-subcategory'
import { isEmpty, find, get } from 'lodash'
import { isoDateToms } from 'Utils/MomentHelpers'

const monthDays = [...Array(31)]
  .map((_, i) => i + 1)
  .map(day => ({ value: day, text: day }))

const LoaderContainer = ({
  loading,
  children
}) => {
  return (
    <ScheduleForm>
      {
        loading ? <Loader overlayOpacity={0.5} /> : null
      }
      {children}
    </ScheduleForm>
  )
}

const NewReportForm = ({
  t,
  submit,
  closeNewReport,
  sites,
  loading,
  selectedOrgId,
  openModal,
  initialReportName,
  initialSelectedSites,
  initialNotifications,
  initialTimeZone,
  initialFrequencyType,
  initialStartDate,
  initialDayOfMonth,
  initialDaysOfWeek,
  initialNotificationHour,
  categories,
  initialFilteredAlerts,
  alerts,
  saveReport,
  userId,
  selectedReportId,
  editReport,
  theme,
  hasFeatureAccess,
  initialIncludeAssignedUserInfo
}) => {
  const Steps = { 'SITES': 1, 'EVENTS': 2, 'CONFIGURATION': 3 }
  const { timeZoneNames, timeOptionsInHours } = useAutomatedReport()
  const [step, setStep] = useState(Steps.SITES)
  const [reportName, setReportName] = useState(initialReportName)
  const [selectedSite, setSelectedSite] = useState(initialSelectedSites)
  const [notifications, setNotifications] = useState(initialNotifications)
  const [filteredAlerts, setFilteredAlerts] = useState(initialFilteredAlerts)
  const [timeZone, setTimeZone] = useState(initialTimeZone)
  const [frequencyType, setFrequencyType] = useState(initialFrequencyType)
  const [startDate, setStartDate] = useState(initialStartDate)
  const [dayOfMonth, setDayOfMonth] = useState(initialDayOfMonth)
  const [daysOfWeek, setDaysOfWeek] = useState(initialDaysOfWeek)
  const [notificationHour, setNotificationHour] = useState(initialNotificationHour)
  const [hasIncludeAssignedUserInfo, setHasIncludeAssignedUserInfo,] = useState(initialIncludeAssignedUserInfo)
  const [validSitesForm, setValidSitesForm] = useState(false)
  const [validEventsForm, setValidEventsForm] = useState(false)
  const [valifConfigForm, setValifConfigForm] = useState(false)
  
  const frequency = [
    { value: 1, text: t('Daily') },
    { value: 2, text: t('Weekly') },
    { value: 3, text: t('Monthly') }
  ]

  useEffect(() => {
    if (!isEmpty(selectedSite) && reportName) {
      setValidSitesForm(true)
    }
  }, [reportName, selectedSite])

  useEffect(() => {
    if (frequencyType && startDate && notificationHour !== null &&
      ((frequencyType === 1) || (frequencyType === 2 && daysOfWeek) || (frequencyType === 3 && dayOfMonth))) { 
      setValifConfigForm(true)
    } else {
      setValifConfigForm(false)
    }
  }, [frequencyType, startDate, notificationHour, dayOfMonth, daysOfWeek, dayOfMonth])

  useEffect(() => {
    const isValid = categories.map(category => get(filteredAlerts, category, [])
      .filter(alert => alert.enabled))
    setValidEventsForm(isValid.flatMap(alert => alert).length > 0)
  }, [filteredAlerts])

  const isLast = step === Steps.CONFIGURATION

  const nextPage = () => {
    if (step === Steps.SITES) {
      setStep(Steps.EVENTS)
    } else if (step === Steps.EVENTS) {
      setStep(Steps.CONFIGURATION)
    } else {
      submit()
    }
  }

  const disableNextButton = () => {
    if (step === Steps.SITES) {
      return validSitesForm
    } else if (step === Steps.EVENTS) {
      return validEventsForm
    } else if (step === Steps.CONFIGURATION) {
      return valifConfigForm
    } else {
      return false
    }
  }

  const saveAlerts = (alertList) => {
    const newNotifications = notifications.map(notification => {
      const findNotification = find(alertList, alert => alert.ruleId === notification.id)
      if (findNotification) {
        return ({
          ...notification,
          enabled: findNotification.enabled
        })
      }
      return notification
    })
    setNotifications(newNotifications)
  }

  useEffect(() => {
    const newFilteredAlerts = notifications.reduce((acum, currentAlert) => {
      acum[currentAlert.deviceType] = [...get(acum, `${currentAlert.deviceType}`, []), currentAlert]
      return acum
    }, {})
    setFilteredAlerts(newFilteredAlerts)
  }, [notifications])

  const buttonAlertsData = () => {
    setNotifications(alerts)
  }

  const handleSubmit = () => {
    const properties = {
      name: reportName,
      type: frequencyType,
      notificationHour,
      startDate: { seconds: isoDateToms(startDate) / 1000 },
      userId,
      settings: notifications.map(alert => ({
        id: alert.id,
        enabled: alert.enabled,
        notificationEmail: alert.notificationEmail
      })),
      orgUnitIds: selectedSite,
      timeZone,
      frequency: 1,
      id: selectedReportId,
      organizationId: selectedOrgId,
      includeAssignedUserInfo: hasIncludeAssignedUserInfo
    }
    if (properties.type === FREQUENCIES.WEEKLY) {
      properties.daysOfWeek = [ daysOfWeek === DAYS.SUNDAY ? 0 : daysOfWeek ]
    }
    if (properties.type === FREQUENCIES.MONTHLY) {
      properties.dayOfMonth = dayOfMonth
    }
    if (!selectedReportId) {
      saveReport(properties)
    } else {
      editReport(properties)
    }
  }

  const addSite = (siteId) => {
    if (!selectedSite.includes(siteId)) {
      setSelectedSite([...selectedSite, siteId])
    }
  }

  return (
    <GeneralContainer theme={theme}>
      <DrilldownContainer>
        <BreadcrumbContainer onClick={() => closeNewReport()}>
          <Icon name='caret-left' />
          <BreadcrumbTitle>{t('Breadcrumb')}</BreadcrumbTitle>
        </BreadcrumbContainer>
        <Title>{t('AutomatedReport')}</Title>
        <ModalBody>
          <LoaderContainer loading={loading}>
            <Fragment>
              <Stepper fluid={true} ordered={true}>
                <Stepper.Item
                  title={t('SiteSelection')}
                  active={step === Steps.SITES}
                  completed={validSitesForm}
                  disabled={false}
                  onClick={() => { setStep(Steps.SITES) }}
                />
                <Stepper.Item
                  title={t('EventsInclude')}
                  active={step === Steps.EVENTS}
                  completed={validEventsForm}
                  disabled={!validSitesForm}
                  onClick={() => setStep(Steps.EVENTS)}
                />
                <Stepper.Item
                  title={t('ReportConfiguration')}
                  active={step === Steps.CONFIGURATION}
                  completed={valifConfigForm}
                  disabled={!validEventsForm && !validSitesForm}
                  onClick={() => setStep(Steps.CONFIGURATION)}
                />
              </Stepper>
              <div hidden={step !== Steps.SITES}>
                <Paragraph>{t('LegendStep1')}</Paragraph>
                <StepOne>
                  <Input
                    label={t('ReportName')}
                    placeholder={t('NamePlaceholder')}
                    validate={[isRequired]}
                    t={t}
                    value={reportName}
                    onChange={(value) => setReportName(value)}
                    fluid={true}
                  />
                </StepOne>
                <SelectorContainer>
                  <label>{t('Sites')}:</label>
                  <SiteSelector
                    placeholder={t('SitesPlaceholder')}
                    options={sites}
                    multiple={true}
                    validate={[isSelectRequired]}
                    onChange={(value) => { setSelectedSite(value) }}
                    search={true}
                    value={selectedSite}
                  />
                  <IconContainer
                    onClick={() => openModal('dashboardSitesFilter', {
                      closeIcon: true,
                      size: 'small',
                      className: 'modal-height modal-center',
                      closeOnDimmerClick: false,
                      alertsReportForm: true,
                      addSiteFromReportForm: addSite
                    })}
                  >
                    <Icon root='common' name='submenu' size='medium' />
                  </IconContainer>
                </SelectorContainer>
              </div>

              <div hidden={step !== Steps.EVENTS}>
                <Paragraph>{t('LegendStep2')}</Paragraph>
                <ButtonAsAlerts
                  content={t('SameAsAlerts')}
                  onClick={() => buttonAlertsData()}
                  type='link'
                />
                {categories.map(type => {
                  return (
                    <Collapse
                      category={type}
                      key={type}
                      alertInfo={get(filteredAlerts, type, [])}
                      saveAlerts={saveAlerts}
                      tabType='enabled'
                      selectedOrgId={selectedOrgId}
                      t={t}
                      hasFeatureAccess={hasFeatureAccess}
                    >
                      <AlertSubcategory
                        alertInfo={get(filteredAlerts, type, [])}
                        saveAlerts={saveAlerts}
                        selectedOrgId={selectedOrgId}
                        tabType='enabled'
                        t={t}
                        hasFeatureAccess={hasFeatureAccess}
                      />
                    </Collapse>
                  )
                }
                )}
              </div>

              <div hidden={step !== Steps.CONFIGURATION}>
                <Paragraph>{t('LegendStep3')}</Paragraph>
                <TimeWrapper>
                  <Column flex={0.4} style={{ paddingRight: '1rem', width: '25%' }}>
                    <Select
                      label={t('TimeZone')}
                      placeholder={t('TimeZonePlaceholder')}
                      options={timeZoneNames}
                      validate={[]}
                      value={timeZone}
                      onChange={value => setTimeZone(value)}
                      fluid={true}
                      search
                    />
                  </Column>
                  <Column flex={0.4} style={{ paddingRight: '1rem' }}>
                    <Select
                      label={t('Frequency')}
                      placeholder={t('ReceivePlaceholder')}
                      validate={[isRequired]}
                      options={frequency}
                      value={frequencyType}
                      onChange={(value) => {
                        setFrequencyType(value)
                      }}
                      fluid={true}
                    />
                  </Column>
                  <Column flex={0.4} style={{ paddingRight: '1rem', width: '25%' }}>
                    <DatePicker
                      label={t('StartDate')}
                      placeholder='DD/MM/YYYY'
                      disablePast={true}
                      validate={[isRequired]}
                      value={startDate}
                      onChange={(value) => { setStartDate(value) }}
                      fluid={true}
                    />
                  </Column>
                  <Column flex={0.4} style={{ width: '25%' }}>
                    <Select
                      label={t('Time')}
                      placeholder={t('TimeLabel')}
                      options={timeOptionsInHours}
                      validate={[isRequired]}
                      value={notificationHour}
                      onChange={(value) => { setNotificationHour(value) }}
                      fluid={true}
                    />
                  </Column>
                </TimeWrapper>
                <IndividualTime>
                  {
                    frequencyType === FREQUENCIES.WEEKLY
                      ? <FormRow>
                        <Column>
                          <label><strong>{t('DayWeek')}</strong></label>
                          <WeekDaySelector
                            defaultValue={1}
                            validate={[isRequired]}
                            input={{
                              value: daysOfWeek,
                              onChange: (day) => {
                                setDaysOfWeek(day)
                                setDayOfMonth(null)
                              }
                            }}
                          />

                        </Column>
                      </FormRow>
                      : null
                  }
                  {
                    frequencyType === FREQUENCIES.MONTHLY
                      ? <FormRow>
                        <Column flex={0.1}>
                          <Select
                            label={t('DayMonth')}
                            options={monthDays}
                            validate={[isRequired]}
                            value={dayOfMonth}
                            onChange={(value) => {
                              setDayOfMonth(value)
                              setDaysOfWeek(null)
                            }}
                          />
                        </Column>
                      </FormRow>
                      : null
                  }
                </IndividualTime>
                <Column flex={0.1}>
                  <StyledCheckbox
                    label={t('AssignedUserCheck')}
                    checked={hasIncludeAssignedUserInfo}
                    onChange={(checked) => setHasIncludeAssignedUserInfo(checked)}
                  />
                  </Column>
              </div>
            </Fragment>
            <ButtonContainer>
              <Button
                content={t('Cancel')} size='medium' type='secondary' onClick={() => closeNewReport()}
              />
              <Button
                content={!isLast ? t('Next') : t('Confirm')}
                size='medium'
                type='primary'
                onClick={() => {
                  if (isLast) {
                    handleSubmit()
                    closeNewReport()
                  } else {
                    nextPage()
                  }
                }}
                disabled={!disableNextButton()}
              />
            </ButtonContainer>
          </LoaderContainer>
        </ModalBody>
      </DrilldownContainer>
    </GeneralContainer>
  )
}

NewReportForm.displayName = 'AutomatedReportModal'
NewReportForm.propTypes = {
  sites: PropTypes.array,
  t: PropTypes.func,
  frequency: PropTypes.array,
  submit: PropTypes.func,
  loading: PropTypes.bool
}

NewReportForm.defaultProps = {
  reportNames: []
}

export default (NewReportForm)
